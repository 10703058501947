.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 1023px) {
  .chat-page {
    min-width: 600px;
  }
}
